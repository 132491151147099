.centered {
    text-align: center;
}

.backgroud {
    background-color: #C4C4C4;
}

.padding {
    padding: 30px 50px;
    min-height: 100vh;
}

.labelStrengthPassword {
    display: block;
    posistion: relative;
    font-size: small;
    font-weight: 500;
    width: 100%;
    color: #727272
}

.formHint{
    font-size: 0.6rem;
    line-height: 1.4;
    margin: -5px auto 5px;
    color: #999;
}

.strengthPassword {
    width: 0;
    height: 15px;
    background: transparent;
    transition: all 300ms ease-in-out;
}

.strengthPassword[dataScore="1"] {
    width: 5%;
    background: #f44336;
    border: 1px solid #aaaaaa;
}
.strengthPassword[dataScore="2"] {
    width: 25%;
    background: #ffb652;
    border: 1px solid #aaaaaa;
}
.strengthPassword[dataScore="3"] {
    width: 50%;
    background: #ffe31d;
    border: 1px solid #aaaaaa;
}
.strengthPassword[dataScore="4"] {
    width: 75%;
    background: #cdf441;
    border: 1px solid #aaaaaa;
}
.strengthPassword[dataScore="5"] {
    width: 100%;
    background: #6df424;
    border: 1px solid #aaaaaa;
}

